import React, { useEffect, useState } from "react";
import "./signup.css";

const Calendar = ({ data, setData, emptyField }) => {
  const [maxDateString, setMaxDateString] = useState("");
  const [isSafari, setIsSafari] = useState(true);
  const [isFocus, setIsFocus] = useState(false)

  useEffect(() => {
    const userAgent = navigator.userAgent;
   
    const safariCheck = /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
    setIsSafari(safariCheck);
  }, []); 
  
  useEffect(() => {
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 14);
    setMaxDateString(maxDate.toISOString().split('T')[0]);
  }, []);

  const handleDateChange = (e) => {
    const value = e.target.value;
    setData(value);
  };

  const handleDateChangeForSafari = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/g, "");
    if (value.length > 4) value = value.slice(0, 4) + "-" + value.slice(4);
    if (value.length > 7) value = value.slice(0, 7) + "-" + value.slice(7, 10);
    value = value.slice(0, 10);

    setData(value);
  };

  return (
    isSafari ? ( <input
      value={data}
      type="text"
      min="1925-01-01"
      max={maxDateString}
      maxLength={10}
      placeholder={isFocus ? "yyyy-mm-dd" : "Дата рождения*"}
      className={emptyField === "birthDate" ? "error" : "input"}
      onChange={handleDateChangeForSafari}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
    />):
   ( <input
      value={data}
      type={isFocus ? "date" : "text"}
      min="1925-01-01"
      max={maxDateString}
      maxLength={10}
      placeholder="Дата рождения*"
      className={emptyField === "birthDate" ? "error" : "input"}
      onChange={handleDateChange}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
    />)
  );
};

export default Calendar;

import axios from "axios";
import { baseUrl } from "config";

const instance = axios.create({
  baseURL: baseUrl,
});
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      if (error.response.status === 403 || error.response.status === 401) {
        await new Promise((resolve) => {
          localStorage.clear()
          resolve();
        });
        window.location.href = "/login";
      }
    }

    return Promise.reject(error);
  },
);

export default instance;

import React, { useEffect, useRef, useState } from "react";
import Button from "../Base/Button/Button";
import Input from "../Base/Input/Input";
import axios from "axios";
import WellDone from "./WellDone/WellDone";
import Captcha from "components/Captcha/Captcha";
import { Link } from "react-router-dom";
import { baseUrl } from "../../config";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import "./signup.css";
import Calendar from "./calendare";
import { isEmailValid, isPasswordValid, isValidDateInRange } from "utils/validation";

export default function SignUp() {
  const token = useRef("");
  const [error, setError] = useState({});
  const [wellDone, setWellDone] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [emptyField, setEmptyField] = useState("");
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [data, setData] = useState({
    name: "",
    surname: "",
    fatherName: "",
    alias: "",
    birthDate: "",
    email: "",
    password: "",
    confirm: "",
  });

  useEffect(() => {
    if (!captcha) {
      setIsDisabled(true);
      return;
    }
    if (!isAgree) {
      setIsDisabled(true);
      return;
    }
    if(!isPasswordValid(data.confirm)){
      setIsDisabled(true);
      return;
    }
    if(!isEmailValid(data.email)){
      setIsDisabled(true);
      return;
    }

    if(data.password !== data.confirm){
      setIsDisabled(true);
      return;
    }

    if(!data.alias){
      setIsDisabled(true);
      return;
    }
    setIsDisabled(false);
  }, [captcha, data.alias, data.birthDate, data.confirm, data.email, data.password, isAgree]);
  
  if (wellDone) {
    return <WellDone token={token.current} />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!data?.birthDate || !isValidDateInRange(data.birthDate)) {
      setEmptyField("birthDate");
      return;
    }
    if (!data?.alias ) {
      setEmptyField("alias");
      return;
    }
    
   

    try {
      const res = await axios.post(`${baseUrl}/author/registration`, data);
      setWellDone(true);
      token.current = res.data.token;
    } catch (e) {
      setError(e.response.data);
    }
  };

  const handleCaptchaChange = () => {
    setCaptcha(true);
  };

  const handleCaptchaExpired = () => {
    setCaptcha(false);
  };

  const handleRadioChange = () => {
    setIsAgree(true);
  };

  return (
    <div className="register__container">
      <form className="register" onSubmit={handleSubmit}>
        <h1>Регистрация</h1>
        <Link to={"/login"} className="redirectLink text-center">
          Вход
        </Link>
        <div className="input__container">
          <Input
            className={(error.email && !isEmailValid(data.email)) && "error"}
            type={"text"}
            value={data.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
            placeholder={"E-mail*"}
          />
          {!isEmailValid(data.email)&& data.email && <span>Введите корректный адрес электронной почты!</span>}
          {error.email && <span>{error.email}!</span>}

          <Input
            type={"text"}
            className={error.surname && "error"}
            value={data.surname}
            onChange={(e) => setData({ ...data, surname: e.target.value })}
            placeholder={"Фамилия*"}
          />
          {error.surname && <span>{error.surname}!</span>}

          <Input
            type={"text"}
            value={data.name}
            className={error.name && "error"}
            onChange={(e) => setData({ ...data, name: e.target.value })}
            placeholder={"Имя*"}
          />
          {error.name && <span>{error.name}!</span>}
          <Input
            type={"text"}
            value={data.fatherName}
            onChange={(e) => setData({ ...data, fatherName: e.target.value })}
            placeholder={"Отчество"}
          />
          <Calendar data={data.birthDate} setData={(value) =>setData({ ...data, birthDate: value })}  emptyField={emptyField}/>
          {(emptyField === "birthDate" || (!isValidDateInRange(data.birthDate) && data.birthDate )) && <span>Введите корректную дату рождения!</span>}

          <Input
            type={"text"}
            value={data.alias}
            className={error.alias && "error"}
            onChange={(e) => setData({ ...data, alias: e.target.value })}
            placeholder={"Псевдоним*"}
          />
          {emptyField === "alias" && !data.alias && (
            <span>Поле не может быть пустым!</span>
          )}

          <label className="password">
            <Input
              type={isPasswordShow ? "text" : "password"}
              className={error.password && "error"}
              value={data.password}
              onChange={(e) => setData({ ...data, password: e.target.value })}
              placeholder={"Придумайте пароль*"}
            />
            {isPasswordShow ? (
              <IoEyeOutline
                onClick={() => setIsPasswordShow(!isPasswordShow)}
              />
            ) : (
              <IoEyeOffOutline
                onClick={() => setIsPasswordShow(!isPasswordShow)}
              />
            )}
          </label>
          {!isPasswordValid(data.password) && (
            <span>Пароль должен быть длиннее 8 символов и содержать заглавную букву, цифру и спец. символ!</span>
          )}

          {error.password && <span>{error.password}!</span>}
          <label className="password">
            <Input
             type={isPasswordShow ? "text" : "password"}
              value={data.confirm}
              onChange={(e) => setData({ ...data, confirm: e.target.value })}
              placeholder={"Повторите пароль*"}
            />
            {isPasswordShow ? (
              <IoEyeOutline
                onClick={() => setIsPasswordShow(!isPasswordShow)}
              />
            ) : (
              <IoEyeOffOutline
                onClick={() => setIsPasswordShow(!isPasswordShow)}
              />
            )}
          </label>
        </div>
        <div className="radio">
          <input
            type="radio"
            className="custom-input"
            id="customCheck1"
            value={isAgree}
            onChange={handleRadioChange}
          />
          <label className="custom-label" htmlFor="customCheck1">
            <Link
              to={"/data-processing"}
              className="data-processing-link"
              target="_blank"
            >
              Согласен на обработку персональных данных
            </Link>
          </label>
        </div>
        <Captcha
          onChange={handleCaptchaChange}
          onExpired={handleCaptchaExpired}
        />
        <Button disabled={isDisabled} type="submit" value={"Войти"} />
      </form>
    </div>
  );
}

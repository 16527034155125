import PaymentData from "components/adminComponents/paymentData/PaymentData";
import Layout from "components/layout/Layout";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./AdminAuthor.module.css";
import Input from "components/Base/Input/Input";
import Sorted from "components/adminComponents/Sorted/Sorted";
import Description from "components/adminComponents/description/Description";
import { useQuery } from "@tanstack/react-query";
import { useFilter } from "utils/useFilter";
import Pagination from "components/adminComponents/pagination/Pagination";
import { authorService } from "service/Author.service";
import Header from "components/header/Header";
import FullBook from "../../../components/adminComponents/book/FullBook";
import { PiBooksLight } from "react-icons/pi";
import { GiTakeMyMoney } from "react-icons/gi";
import { IoSwapVerticalOutline } from "react-icons/io5";
import { PiCurrencyRubBold } from "react-icons/pi";

const AdminAuthor = () => {
  const { id } = useParams();
  const { data: author } = useQuery({
    queryKey: ["authorID"],
    queryFn: () => authorService.getAuthorById(id),
  });
  const [filter, setFilter] = useState("");
  const [isDesc, setIsDesc] = useState(false);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const map = [
    {
      icon: PiBooksLight,
      name: "Книги",
    },
    {
      icon: GiTakeMyMoney,
      name: "Выплаты",
    },
  ];
  const [pageCount, setPageCount] = useState(1);
  const { newBook } = useFilter(filter, search, author?.books, [], isDesc);
  const showCountAds = author?.books.reduce(
    (acc, book) => acc + book.showCountAds,
    0,
  );
  return (
    <Layout>
      <div className={styles.wrapper}>
        <Header />
        <PaymentData
          showAll={false}
          showCount={showCountAds}
          subscribers={author?.subscribers.length}
        />
        <div className={styles.header}>
          <div className={styles.info}>
            <div className={styles.data}>
              <p className={styles.title}>
                {author?.name + " " + author?.surname}
              </p>
              <p className={styles.email}>{author?.email}</p>
            </div>

            <div className={styles.subscribers}>
              <span>Количество подписок:</span>
              <p>{author?.subscribers.length}</p>
            </div>
          </div>
        </div>

        <div className={styles.tabs}>
          <div className={styles.buttons}>
            {map.map((item, i) => (
              <div
                key={item.icon + 1}
                className={`${styles.item} ${count === i && styles.itemActive}`}
                onClick={() => setCount(i)}
              >
                <item.icon />
                <p>{item.name}</p>
              </div>
            ))}
          </div>
          <Input
            placeholder={"Поиск"}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className={styles.input}
          />
        </div>
        {!count ? (
          <>
            <div className={styles.sorted}>
              <Sorted
                setFilter={setFilter}
                isDesc={isDesc}
                setIsDesc={setIsDesc}
              />
            </div>
            <div className={styles.items}>
              <Description />
              {newBook?.length ? (
                <>
                  {newBook[pageCount - 1]?.map((item, i) => (
                    <FullBook count={i + 1} {...item} key={i} />
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className={styles.right}>
              <Pagination
                totalPages={newBook?.length}
                currentPage={pageCount}
                onChangePage={setPageCount}
              />
            </div>
          </>
        ) : (
          <div className={styles.table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <div>
                      <p>Дата запроса</p>
                      <IoSwapVerticalOutline />
                    </div>
                  </th>
                  <th>
                    <div>
                      <p>Сумма запроса</p>
                      <IoSwapVerticalOutline />
                    </div>
                  </th>
                  <th>
                    <div>
                      <p>Дата выплаты</p>
                      <IoSwapVerticalOutline />
                    </div>
                  </th>
                  <th>
                    <div>
                      <p>Остаток</p>
                      <IoSwapVerticalOutline />
                    </div>
                  </th>
                  <th>
                    <div>
                      <p>Статус</p>
                      <IoSwapVerticalOutline />
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                <td>2024-07-24</td>
                <td>
                  <PiCurrencyRubBold /> 2500
                </td>
                <td></td>
                <td>
                  <PiCurrencyRubBold /> 2550
                </td>
                <td className={styles.pending}>Обрабатывается</td>
                <td>
                  <Button
                    value={"Выплатить"}
                    onClick={() => console.log("Выплатить")}
                  />
                </td>
              </tr> */}
                <tr>
                  <td></td>
                  <td>
                    <PiCurrencyRubBold />
                  </td>
                  <td></td>
                  <td>
                    <PiCurrencyRubBold />
                  </td>
                  <td className={styles.success}></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default AdminAuthor;

export const isEmailValid = (email) => {
  const pattern = /^[a-zA-Z0-9._%+-]+[a-zA-Z0-9]+@[a-z](?:[a-z0-9-]*[a-z0-9])?\.[a-zA-Z]{2,}$/;
  const match = email.match(pattern);
  return Boolean(match);
};

export const isPasswordValid = (password) => {
  const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[_!@#$%^&*])[A-Za-z\d!@#$_%^&*]{8,}$/
  const match = password.match(pattern)
  return Boolean(match)
}

export const isValidDateInRange = (dateString) => {
  const inputDate = new Date(dateString);
  const minDate = new Date("1925-01-01");
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 14);

  return (
    inputDate instanceof Date &&
    !isNaN(inputDate) &&
    inputDate >= minDate &&
    inputDate <= maxDate
  );
};

function ibanToDigits(iban) {
  return iban.toUpperCase().split('').map(char => {
    if (char >= 'A' && char <= 'Z') {
      return (char.charCodeAt(0) - 'A'.charCodeAt(0) + 10).toString();
    } else {
      return char;
    }
  }).join('');
}


export const isValidIban = (iban) => {
  iban = iban.replace(/\s+/g, '').toUpperCase();

  if (iban.length !== 28 && iban.length !== 20) {
    return false;
  }
  const countryCode = iban.slice(0, 2);
  if (countryCode !== 'BY' && countryCode !== 'KZ') {
    return false;
  }
  const reorderedIban = iban.slice(4) + iban.slice(0, 4);
  const ibanDigits = ibanToDigits(reorderedIban);
  let remainder = '';
  for (let i = 0; i < ibanDigits.length; i++) {
    remainder = (remainder + ibanDigits[i]) % 97;
  }
  return remainder === 1;
}

export const isValidRAN = (accountNumber) => {
  accountNumber = accountNumber.replace(/\D/g, '');
  return accountNumber.length === 20;
}
